<template>
  <div class="product-comment">
    <!-- <div class="product-comment__title">{{ $t('product.detail.comment') }}</div> -->
    <!-- <div class="product-comment__summary">
      <comment-summary :comment-summary="commentSummary"></comment-summary>
    </div> -->
    <!-- <div class="product-comment__list">
      <comment-contents
        v-for="item in commentList"
        :key="item.id"
        :comment-info="item"
      ></comment-contents>
    </div>
    <div v-if="total > 10" class="product-comment__view-all-btn">
      <op-link @click="goCommentList">{{ $t('product.detail.viewAllComments') }}</op-link>
    </div> -->
    <!-- 评价操作 -->
    <div class="comment-operation-module">
      <div class="operation-tips-module">
        <template v-if="!commentList.length && currentReview === 1">
          <div class="tips-title">{{ $t('product.comment.noReviewsYet') }}</div>
          <div class="tips-desciption">{{ $t('product.comment.writeFirstReview') }}</div>
        </template>
        <template v-else>
          <div class="tips-statistics">
            {{ $t('product.comment.CustomerReview') }} （{{ commentStatistics.commentCount }}）
          </div>
        </template>
      </div>
      <div class="operation-module">
        <div class="operation-item" @click="commentWriteVisible = true">
          {{ $t('product.comment.writeReview') }}
        </div>
      </div>
    </div>
    <!-- 评价统计 -->
    <template v-if="!commentList.length && currentReview == 1"></template>
    <div v-else class="comment-filter-module">
      <div class="filter-statistics-module">
        <div class="statistics-info-module">
          <div class="statistics-num-module">
            <span class="num-custom">
              {{ commentStatistics.starLevelAvg }}
            </span>
            /5.0
          </div>
          <div class="statistics-star-module">
            <a-rate v-model="starLevel" allow-half disabled />
          </div>
        </div>
        <div class="statistics-description-module">
          {{ $t('product.comment.averageRating') }}
        </div>
        <div class="statistics-details-module">
          <div v-for="item in starStatisticsLevels" :key="item.key" class="statistics-details-item">
            <div class="details-star-level">{{ item.label }}</div>
            <div class="details-star">
              <a-rate :default-value="1" :count="1" disabled />
            </div>
            <div class="details-star-progress">
              <a-progress
                :percent="formatCommentStar(commentStatistics[item.key])"
                stroke-color="#00B876"
                :show-info="false"
              />
            </div>
            <div class="details-star-progress-rate">
              {{ commentStatistics[item.key] }}
            </div>
          </div>
        </div>
      </div>
      <div class="filter-operation-module">
        <div
          v-for="item in reviewOptions"
          :key="item.value"
          class="operation-item"
          :class="{ selected: currentReview === item.value }"
          @click="handleReviewChange(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <!-- 写评价 -->
    <div v-if="commentWriteVisible" class="comment-write-module">
      <comment-write
        :visible.sync="commentWriteVisible"
        :good-id="goodId"
        @confirm="handleCommentWriteConfirm"
      />
    </div>
    <div class="comment-list-module">
      <div v-for="(item, index) in commentList" :key="index" class="comment-item">
        <div class="comment-basic-info">
          <div class="basic-info">
            <div class="info-title">{{ item.name }}</div>
            <div class="info-description">{{ item.commentTime | formatDateTime }}</div>
          </div>
          <div class="basic-info">
            <div class="info-title">
              <a-rate :default-value="formatCommentStaLevelAvg(Number(item.starLevel))" disabled />
            </div>
            <!-- <div class="info-description">Black small</div> -->
          </div>
        </div>
        <div class="comment-context">
          {{ item.commentContent }}
        </div>
        <div class="comment-picture-module">
          <div
            v-for="(childItem, childIndex) in item.commentImg"
            :key="childIndex"
            class="picture-item"
          >
            <img class="picture-img" :src="childItem" />
          </div>
        </div>
        <!-- <div class="comment-description">1 reviews</div> -->
      </div>
      <div v-if="!commentList.length && currentReview !== 1" class="comment-no-data-item">
        <div class="no-data-tips">{{ $t('product.comment.noResultsFound') }}</div>
        <div class="no-data-button-module">
          <div class="button-item" @click="handleViewAllClick">
            {{ $t('product.comment.viewAll') }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="commentList.length" class="comment-list-pagination-module">
      <op-pagination
        :total="total"
        :current-size="currentSize"
        :page-size="pageSize"
        @change="handlePaginationChange"
      />
    </div>
  </div>
</template>

<script>
import { getProductCommentList, getProductCommentStarStatistics } from '@/api'
export default {
  props: {
    commentSummary: {
      type: Object,
      default: () => ({}),
    },
    goodId: {
      type: [String, Number],
      default: -1,
    },
  },
  data() {
    return {
      commentList: [],
      total: 0,
      currentSize: 1,
      pageSize: 10,
      currentReview: '',
      reviewOptions: [],
      commentStatistics: {
        commentCount: 0,
        starLevelAvg: 0,
        oneStarPercent: '',
        twoStarPercent: '',
        threeStarPercent: '',
        fourStarPercent: '',
        fiveStarPercent: ''
      },
      starStatisticsLevels: [
        {
          label: '5.0',
          key: 'fiveStarPercent'
        },
        {
          label: '4.0',
          key: 'fourStarPercent'
        },
        {
          label: '3.0',
          key: 'threeStarPercent'
        },
        {
          label: '2.0',
          key: 'twoStarPercent'
        },
        {
          label: '1.0',
          key: 'oneStarPercent'
        }
      ],
      commentWriteVisible: false
    }
  },
  computed: {
    starLevel() {
      return +this.commentStatistics.starLevelAvg % 1 > 0
        ? +String(this.commentStatistics.starLevelAvg).split('.')[0] + 0.5
        : +this.commentStatistics.starLevelAvg
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      const reviewOptions = [
        // 评价查询类型：1:ALL 2:with-img（非必填，默认1）
        {
          label: this.$t('product.comment.AllReviews'),
          value: 1
        },
        {
          label: this.$t('product.comment.withImages'),
          value: 2
        }
      ]
      this.reviewOptions = reviewOptions
      this.currentReview = this.reviewOptions[0].value
      this.currentSize = 1
      this.getCommentStatistics()
      this.getCommentList()
    },
    formatCommentStar(data) {
      return Number(String(data).replace('%', ''))
    },
    formatCommentStaLevelAvg(data) {
      return +data % 1 > 0
        ? +String(data).split('.')[0] + 0.5
        : +data
    },
    async getCommentStatistics() {
      // goodsId number 必须 商品id mock: 12
      // commentCount number 必须 总评价条数 mock: 12
      // starLevelAvg string 必须 评价平均分 mock: 4.5
      // oneStarPercent string 必须 一颗星评价百分比 mock: 10%
      // twoStarPercent string 必须 两颗星评价百分比 mock: 33%
      // threeStarPercent string 必须 三颗星评价百分比 mock: 50%
      // fourStarPercent string 必须 四颗星评价百分比 mock: 17%
      // fiveStarPercent string 必须 五颗星评价百分比
      const params = {
        goodsId: this.goodId
      }
      const { code, data } = await getProductCommentStarStatistics(params)
      if (code === 200) {
        data.starLevelAvg = String(data.starLevelAvg)
        this.commentStatistics = data
      }
    },
    async getCommentList() {
      const params = {
        // start: this.localPagination.pageSize * (this.localPagination.current - 1),
        // size: this.localPagination.pageSize,
        size: this.pageSize,
        start: this.pageSize * (this.currentSize - 1),
        goodsId: this.goodId,
        type: this.currentReview || 1
      }
      const { totalRecords, data } = await getProductCommentList(params)
      this.total = totalRecords || 0
      this.commentList = data || []
    },
    goCommentList() {
      this.$router.push({
        path: '/comments',
        query: {
          goodsId: this.goodId,
        },
      })
    },
    handleReviewChange(item) {
      this.currentSize = 1
      this.currentReview = item.value
      this.getCommentList()
    },
    handlePaginationChange(currentSize, sizes) {
      this.currentSize = currentSize
      this.pageSize = sizes
      this.getCommentList()
    },
    handleViewAllClick() {
      this.currentSize = 1
      this.currentReview = this.reviewOptions[0].value
      this.getCommentList()
    },
    handleCommentWriteConfirm() {
      this.load()
    }
  },
}
</script>
<style lang="less" scoped>
/deep/.anticon {
  font-size: 16px;
}
.product-comment {
  margin: 40px 0;
  &__title {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    color: var(--color-title);
    letter-spacing: 0.2px;
  }
  .comment-list__pagination {
    display: flex;
    justify-content: flex-end;
    padding: 24px 0;
  }
  &__view-all-btn {
    display: flex;
    justify-content: center;
  }
}
.comment-operation-module {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px dashed #f4f6f8;
  .operation-tips-module {
    .tips-statistics {
      color: rgba(0, 0, 0, 1);
      font-size: 22px;
      font-weight: 500;
      font-family: PingFang SC;
      line-height: 30px;
    }
    .tips-title {
      margin-bottom: 12px;
      color: rgba(0, 0, 0, 1);
      font-size: 22px;
      font-weight: 500;
      font-family: PingFang SC;
      line-height: 30px;
    }
    .tips-desciption {
      color: rgba(71, 81, 106, 1);
      font-size: 14px;
      font-weight: 400;
      font-family: PingFang SC;
      line-height: 20px;
    }
  }
  .operation-module {
    .operation-item {
      padding: 0 24px;
      line-height: 48px;
      border-radius: 4px;
      opacity: 1;
      border: 1px solid rgba(0, 0, 0, 1);
      color: rgba(42, 51, 74, 1);
      font-size: 14px;
      font-weight: 700;
      font-family: PingFang SC;
      cursor: pointer;
    }
  }
}
.comment-filter-module {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px dashed #f4f6f8;
  .filter-statistics-module {
    .statistics-info-module {
      display: flex;
      .statistics-num-module {
        margin-right: 8px;
        color: rgba(24, 30, 45, 1);
        font-size: 32px;
        font-weight: 400;
        font-family: Rubik;
        line-height: 48px;
        letter-spacing: 0.2px;
        .num-custom {
          color: rgba(24, 30, 45, 1);
          font-size: 56px;
          font-weight: 400;
          line-height: 72px;
          letter-spacing: 0.2px;
        }
      }
      .statistics-star-module {
        align-self: center;
        margin-top: 20px;
      }
    }
    .statistics-description-module {
      color: rgba(71, 81, 106, 1);
      font-size: 14px;
      font-weight: 400;
      font-family: PingFang SC;
      line-height: 20px;
    }
    .statistics-details-module {
      margin-top: 24px;
      .statistics-details-item {
        min-width: 300px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        &:last-child {
          margin-bottom: 0;
        }
        .details-star-level {
          margin-right: 8px;
          color: rgba(24, 30, 45, 1);
          font-size: 14px;
          font-weight: 400;
          font-family: Rubik;
          line-height: 20px;
        }
        .details-star {
          margin-right: 12px;
        }
        .details-star-progress {
          width: 100%;
          margin-right: 12px;
        }
        .details-star-progress-rate {
          width: 60px;
          color: rgba(90, 113, 132, 1);
          font-size: 14px;
          font-weight: 400;
          font-family: Roboto;
          line-height: 20px;
        }
      }
    }
  }
  .filter-operation-module {
    display: flex;
    .operation-item {
      padding: 0 33px;
      margin-right: 24px;
      height: 48px;
      line-height: 48px;
      color: rgba(137, 145, 164, 1);
      font-size: 14px;
      font-weight: 700;
      font-family: PingFang SC;
      border-radius: 4px;
      border: 1px solid rgba(0.5373958349227905, 0.5683278441429138, 0.6416666507720947, 0.5);
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.selected {
        color: #00b876;
        background: rgba(229, 248, 241, 1);
        border: 1px solid #00b876;
      }
    }
  }
}
.comment-list-module {
  .comment-item {
    padding: 32px 0;
    border-bottom: 1px dashed #f4f6f8;
    .comment-basic-info {
      display: flex;
      margin-bottom: 24px;
      .basic-info {
        margin-right: 80px;
        &:last-child {
          margin-right: 0;
        }
        .info-title {
          margin-bottom: 8px;
          color: rgba(24, 30, 45, 1);
          font-size: 14px;
          font-weight: 500;
          font-family: PingFang HK;
          line-height: 20px;
          /deep/.anticon {
            font-size: 16px;
          }
        }
        .info-description {
          color: rgba(157, 170, 194, 1);
          font-size: 14px;
          font-weight: 400;
          font-family: PingFang HK;
          line-height: 20px;
        }
      }
    }
    .comment-context {
      margin-bottom: 24px;
      color: rgba(71, 81, 106, 1);
      font-size: 16px;
      font-weight: 400;
      font-family: Roboto;
      line-height: 28px;
      word-break: break-all;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
    }
    .comment-picture-module {
      margin-bottom: 24px;
      display: flex;
      .picture-item {
        margin: 0 22px 22px 0;
        .picture-img {
          width: 96px;
          height: 96px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .comment-description {
      color: rgba(157, 170, 194, 1);
      font-size: 14px;
      font-weight: 400;
      font-family: PingFang HK;
      line-height: 20px;
    }
  }
  .comment-no-data-item {
    padding: 120px 0 40px;
    .no-data-tips {
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      color: rgba(71, 81, 106, 1);
      font-size: 16px;
      font-weight: 400;
      font-family: PingFang SC;
      line-height: 24px;
    }
    .no-data-button-module {
      display: flex;
      justify-content: center;
      .button-item {
        padding: 0 20px;
        line-height: 36px;
        color: rgba(137, 145, 164, 1);
        font-size: 14px;
        font-weight: 700;
        font-family: PingFang SC;
        border-radius: 4px;
        opacity: 1;
        border: 1px solid rgba(0.5373958349227905, 0.5683278441429138, 0.6416666507720947, 0.5);
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .comment-operation-module {
    flex-direction: column;
    align-items: flex-start;
    .operation-tips-module {
      margin-bottom: 16px;
    }
  }
  .comment-filter-module {
    flex-direction: column;
    align-items: flex-start;
    .filter-statistics-module {
      margin-bottom: 16px;
    }
  }
  .comment-picture-module {
    flex-wrap: wrap;
  }
}
</style>
