<template>
  <div class="product-image-list">
    <div class="product-image-list__image">
      <div class="product-image-list__image__current-image">
        <img v-compress="{ src: activeMaterial.materialUrl }" />
      </div>
      <op-swiper
        v-if="materialList && materialList.length > 0"
        :change-page-button="materialList && materialList.length > 4"
        component-id="product-swiper1"
        :active-index="pcSwiperActiveIndex"
        :swiper-option="pcSwiperOption"
      >
        <swiper-slide
          v-for="(item, index) in materialList"
          :key="item.id"
          class="swiper-slide"
          @click.native="setCurrentImage(index)"
        >
          <div class="swiper-slide__image-box">
            <img
              v-compress="{ src: item.materialUrl }"
              :class="[
                activeMaterial.id === item.id ? 'swiper-slide__image-box__image-active' : '',
              ]"
            />
          </div>
        </swiper-slide>
      </op-swiper>
    </div>
    <!-- 商品图片-屏幕小于768下展示 -->
    <div class="product-image-list__image--small">
      <op-swiper
        v-if="materialList && materialList.length > 0"
        :active-index="activeIndex"
        :change-page-button="materialList && materialList.length > 1"
        component-id="product-swiper2"
        :swiper-option="mobileSwiperOption"
      >
        <swiper-slide
          v-for="item in materialList"
          :key="item.id"
          class="swiper-slide"
        >
          <img v-compress="{ src: item.materialUrl }" />
        </swiper-slide>
      </op-swiper>
      <div
        v-else
        class="product-image-list__image--small__default"
      >
        <img
          v-compress="{ src: activeMaterial.materialUrl }"
          class="product-image-list__image--small__default__image"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    materialList: {
      type: Array,
      default: () => [],
    },
    materialId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      activeIndex: 0,
    }
  },

  computed: {
    activeMaterial() {
      return (
        (this.materialList || [])[this.activeIndex] || {
          materialUrl:
            'https://saas-fe-prod.pandova.com/static/pandova-mall/images/product/default-product.png',
          id: null,
        }
      )
    },
    pcSwiperActiveIndex() {
      return this.activeIndex > 4 ? this.activeIndex - 3 : 0
    },
    pcSwiperOption() {
      return this.materialList && this.materialList.length > 4
        ? { slidesPerView: 4, speed: 1 }
        : { slidesPerView: 4, speed: 1, pagination: {} }
    },
    mobileSwiperOption() {
      return this.materialList && this.materialList.length > 1
        ? { speed: 1 }
        : { speed: 1, pagination: {} }
    },
  },

  watch: {
    materialId() {
      const index = (this.materialList || []).findIndex(item => this.materialId === item.id)
      index !== -1 && this.setCurrentImage(index)
    },
  },

  methods: {
    setCurrentImage(index) {
      this.activeIndex = index
    },
  },
}
</script>
<style lang="less" scoped>
.product-image-list {
  margin-right: 24px;
  &__image--small {
    display: none;
  }
  &__image {
    width: 560px;
    &__current-image {
      width: 560px;
      height: 560px;
      margin: 0 auto 16px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .swiper-slide {
      &__image-box {
        width: 140px;
        &__image-active {
          border: 2px solid var(--color-primary-button-background);
        }
      }
      img {
        display: block;
        width: 140px;
        height: 140px;
        margin: 0 auto;
        object-fit: cover;
      }
    }
  }
}
@media (max-width: 1200px) {
  .product-image-list {
    &__image {
      width: 440px;
      &__current-image {
        width: 440px;
        height: 440px;
      }
      .swiper-slide {
        &__image-box {
          width: 110px;
        }
        img {
          width: 110px;
          height: 110px;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .product-image-list {
    &__image {
      width: 360px;
      &__current-image {
        width: 360px;
        height: 360px;
      }
      .swiper-slide {
        &__image-box {
          width: 90px;
        }
        img {
          width: 90px;
          height: 90px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .product-image-list {
    margin-right: 0;
    &__image {
      display: none;
    }
    &__image--small {
      display: block;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 24px;
      .swiper-slide {
        width: calc(100vw - 32px) !important;
        margin: auto;
        img {
          display: block;
          width: calc(100vw - 32px);
          height: calc(100vw - 32px);
          margin: auto;
        }
      }
      &__default {
        display: flex;
        justify-content: center;
        &__image {
          width: calc(100vw - 32px);
          height: calc(100vw - 32px);
        }
      }
    }
  }
}
</style>
