<template>
  <div class="product-detail">
    <op-spin v-if="loading"></op-spin>
    <product-meta-info
      v-else
      :comment-summary="commentSummary"
      :product-meta-info="productDetail"
      :loading-product-info="loading"
    ></product-meta-info>
    <product-main-info :product-main-info="productDetail.detail"></product-main-info>
    <product-extra-info :extra-info="extraInfo"></product-extra-info>
    <product-comment
      v-if="productDetail.commentStatus === 1"
      :comment-summary="commentSummary"
      :good-id="productId"
    ></product-comment>
  </div>
</template>

<script>
import { getProductDetail, getCommentSummary } from '@/api'
import productMetaInfo from './components/product-meta-info.vue'
import productMainInfo from './components/product-main-info.vue'
import productExtraInfo from './components/product-extra-info.vue'
import ProductComment from './components/product-comment.vue'
import { sessionStorage } from '@/utils'

export default {
  components: {
    productMetaInfo,
    productMainInfo,
    productExtraInfo,
    ProductComment,
  },
  props: {},
  data() {
    return {
      productDetail: {},
      extraInfo: {},
      productId: null,
      commentSummary: {},
      loading: false,
    }
  },

  computed: {},

  created() {
    const { firstLevelCode, secondLevelCode, thirdLevelCode } = this.$route.query
    firstLevelCode &&
      sessionStorage.set('channelCode', { firstLevelCode, secondLevelCode, thirdLevelCode })
    this.productId = this.$route.params.productId
    this.getProductDetail()
    this.getCommentSummary()
  },

  methods: {
    async getProductDetail() {
      try {
        this.loading = true
        const { data } = await getProductDetail({ id: this.productId })
        this.productDetail = data || {}
        this.extraInfo = { specList: this.productDetail.specList }
      } finally {
        this.loading = false
      }
    },
    async getCommentSummary() {
      const { data } = await getCommentSummary({ goodsId: this.productId })
      this.commentSummary = data || {}
    },
  },
}
</script>
<style lang="less" scoped>
.product-detail {
  width: 1440px;
  max-width: calc(100% - 80px);
  padding-top: 24px;
  margin: 0 auto;
  user-select: none;
}
@media (max-width: 1024px) {
  width: 1440px;
  .product-detail {
    display: block;
    max-width: 880px;
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .product-detail {
    display: block;
    width: 1440px;
    max-width: calc(100% - 32px);
    margin: 0 auto;
  }
}
</style>
