<template>
  <div class="product-spec-select">
    <div
      v-for="item in specList"
      :key="item.id"
      class="product-spec-select__product-spec"
    >
      <div class="product-spec-select__product-spec__name">{{ item.name }}</div>
      <div class="product-spec-select__product-spec__detail">
        <div
          v-for="specValue in item.valueList"
          :key="specValue.id"
          class="product-spec-select__product-spec__detail__value"
          :class="[
            selectedSpec[item.id] && selectedSpec[item.id].id === specValue.id
              ? 'product-spec-select__product-spec__detail__value--active'
              : '',
            unHasSkuList.includes(`${item.id}-${specValue.id}`)
              ? 'product-spec-select__product-spec__detail__value--disabled'
              : '',
          ]"
          @click="specSelect(item, specValue)"
        >{{ specValue.name }}</div>
      </div>
    </div>
    <div
      v-show="isShowSelectTip"
      class="select-sku-tip"
    >{{ $t('product.detail.skuSelectTip') }}</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    specList: {
      type: Array,
      default: () => [],
    },
    specToSkuInfoMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedSpec: {}, // 选中的规格 ，specId:{data}
      unHasSkuList: [],
      isShowSelectTip: false, // 添加购物车时是否提示选择规格
    }
  },

  computed: {},
  created() {
    this.findDisabledSKu()
  },

  methods: {
    // 规格选择
    specSelect(spec, specValue) {
      if (this.unHasSkuList.includes(`${spec.id}-${specValue.id}`)) {
        return
      }
      if (this.selectedSpec[spec.id] && this.selectedSpec[spec.id].id === specValue.id) {
        this.$delete(this.selectedSpec, spec.id)
        this.findDisabledSKu()
        return
      }
      this.$set(this.selectedSpec, spec.id, { ...specValue, specId: spec.id, specName: spec.name })
      const skuInfoMapKey = Object.keys(this.selectedSpec)
        .map(item => {
          return this.selectedSpec[item].id
        })
        .sort((a, b) => a - b)
        .join('-')
      if (this.specToSkuInfoMap[skuInfoMapKey]) {
        this.$emit('setSkuInfo', skuInfoMapKey)
      }
      this.skuInfoMapKey = skuInfoMapKey
      this.findDisabledSKu()
    },
    // 查找需要判断spec组合下,是否有不能选择的spec 值
    findDisabledSKu() {
      const selectedSpecIds = Object.keys(this.selectedSpec)
      const specLength = this.specList.length
      if (specLength === 0) {
        this.unHasSkuList = []
        return
      }
      if (selectedSpecIds.length === specLength - 1) {
        const lastUnSelectSpec = this.specList.find(item => {
          return !selectedSpecIds.includes(item.id + '')
        })
        this.unHasSkuList = this.calcDisabledSKuList(lastUnSelectSpec)
        return
      }
      if (selectedSpecIds.length === specLength) {
        const unHasSkuList = []
        this.specList.forEach(item => {
          unHasSkuList.push(...this.calcDisabledSKuList(item))
        })

        this.unHasSkuList = unHasSkuList
      }
    },
    // 计算不能选择的sku list
    calcDisabledSKuList(spec) {
      const unHasSkuList = []
      spec.valueList.forEach(item => {
        const currentSelectedSpec = { ...this.selectedSpec, [spec.id]: { id: item.id } }
        const skuId = Object.keys(currentSelectedSpec)
          .map(item => {
            return currentSelectedSpec[item].id
          })
          .sort((a, b) => a - b)
          .join('-')

        if (!this.specToSkuInfoMap[skuId]) {
          unHasSkuList.push(`${spec.id}-${item.id}`)
        }
      })
      return unHasSkuList
    },

    // 添加购物车时判断是否需要选择规格
    isNeedSelectSku() {
      if (this.specList && this.specList.length > Object.keys(this.selectedSpec).length) {
        this.isShowSelectTip = true
        const timer = setTimeout(() => {
          this.isShowSelectTip = false
          clearTimeout(timer)
        }, 2000)
        return true
      }
      return false
    },
    // 添加购物车时 需要的规格信息
    getOrderItemSpecs() {
      return Object.keys(this.selectedSpec).map(item => {
        return {
          specsId: this.selectedSpec[item].specId,
          specsName: this.selectedSpec[item].specName,
          specsValueId: this.selectedSpec[item].id,
          specsValueName: this.selectedSpec[item].name,
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.product-spec-select {
  &__product-spec {
    &__name {
      margin-bottom: 12px;
    }
    &__detail {
      display: flex;
      flex-wrap: wrap;
      &__value {
        height: 40px;
        padding: 0 16px;
        margin: 0 16px 16px 0;
        line-height: 40px;
        color: #c4c8d1;
        cursor: pointer;
        border: 1px solid #c4c8d1;
        border-radius: 4px;
      }
      &__value--active {
        color: var(--color-primary-button-background);
        border: 1px solid var(--color-primary-button-background);
      }
      &__value--disabled {
        cursor: not-allowed !important;
        opacity: 0.5 !important;
      }
    }
  }
  &__product-spec:not(:last-child) {
    margin-bottom: 8px;
  }
}
.select-sku-tip {
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 1000;
  padding: 16px 40px;
  color: #fff;
  background: #403d3cb3;
  border-radius: 4px;
  transform: translateX(-50%);
}
</style>
