<template>
  <div class="product-main-info">
    <div class="product-main-info__title">{{ $t('product.detail.description') }}</div>
    <div
      class="product-main-info__detail"
      v-html="productMainInfo"
    ></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    productMainInfo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },

  computed: {},

  methods: {},
}
</script>
<style lang="less" scoped>
.product-main-info {
  margin-top: 40px;

  &__title {
    margin-bottom: 12px;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    color: var(--color-title);
    letter-spacing: 0.2px;
  }
  &__detail {
    width: 100%;
    overflow: auto;
    word-break: break-all;
    /deep/ img {
      max-width: 100% !important;
    }
  }
}
</style>
