<template>
  <div class="product-meta-info">
    <!-- 商品图片 -->
    <product-image-list
      :material-id="materialId"
      :material-list="productMetaInfo.materialList"
    ></product-image-list>
    <!-- 商品信息 -->
    <div class="product-meta-info__detail">
      <div class="product-meta-info__detail__product-name op-space--vertical-large">
        {{ productMetaInfo.name }}
      </div>
      <div
        v-if="productMetaInfo.commentStatus === 1"
        class="product-meta-info__detail__comments-summary op-space--vertical-large"
      >
        <div class="product-meta-info__detail__comments-summary__star">
          <a-rate v-model="starLevel" allow-half disabled />
        </div>
        <div class="product-meta-info__detail__comments-summary__number">
          <!-- <span>{{ commentSummary.totalQuantity }}</span> -->
          <span>{{ productMetaInfo.commentCount || 0 }}</span>
          <span :style="{ marginLeft: '8px' }">{{ $t('product.detail.comment') }}</span>
        </div>
      </div>
      <div class="product-meta-info__detail__product-price op-space--vertical-large">
        <span v-if="isShowSalePrice" class="product-meta-info__detail__product-price__sale">
          {{ currentSkuInfo.salePrice | formatMoney }}
        </span>
        <span
          v-if="isShowOriginPrice || isThroughOriginPrice"
          class="product-meta-info__detail__product-price__origin"
          :style="{ 'text-decoration': isThroughOriginPrice ? 'line-through' : 'none' }"
        >
          {{ currentSkuInfo.originalPrice | formatMoney }}
        </span>
      </div>
      <product-spec-select
        v-if="isHaveSpec"
        ref="specSelect"
        :spec-list="productMetaInfo.specList"
        :spec-to-sku-info-map="productMetaInfo.specToSkuInfoMap"
        @setSkuInfo="setSkuInfo"
      ></product-spec-select>
      <div class="product-meta-info__detail__product-quantity op-space--vertical-large">
        <div class="product-meta-info__detail__product-quantity__title">
          {{ $t('shoppingCart.quantity') }}
        </div>
        <div class="product-meta-info__detail__product-quantity__body">
          <quantity-input
            v-model="currentSkuInfo.skuNum"
            :max-value="currentSkuInfo.skuStock"
            :is-track-stock="currentSkuInfo.focusStock"
          ></quantity-input>
          <div
            v-if="isShowStock"
            class="product-meta-info__detail__product-quantity__body__stock-tip"
          >
            <span>{{ currentSkuInfo.skuStock | formatNumber }}</span>
            <span class="product-meta-info__detail__product-quantity__body__stock-tip__title">
              {{ $t('product.detail.stock') }}
            </span>
          </div>
        </div>
      </div>
      <div class="product-meta-info__detail__buy-operation">
        <op-button
          :loading="addCartLoading"
          :disabled="buttonDisabled || loadingProductInfo"
          type="outline"
          @click="addCart"
        >
          {{ $t('product.detail.addCart') }}
        </op-button>
        <op-button
          :disabled="buttonDisabled || loadingProductInfo"
          type="primary"
          @click="goPayment"
        >
          {{ $t('product.detail.buyNow') }}
        </op-button>
      </div>
    </div>

    <add-cart-success
      v-if="isShowAddCartSuccessTip"
      @close="isShowAddCartSuccessTip = false"
    ></add-cart-success>
  </div>
</template>

<script>
import addCartSuccess from './add-cart-success.vue'
import ProductSpecSelect from './product-spec-select.vue'
import ProductImageList from './product-image-list.vue'
import { addShoppingCart } from '@/api'
import { localStorage, sessionStorage, MallAnalytics } from '@/utils/index'
import { isEmptyValue } from '@/utils/validator'
import { CART_PRODUCT_VALID, IS_TRACK_STOCK } from '@/constants'
export default {
  components: {
    addCartSuccess,
    ProductSpecSelect,
    ProductImageList,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    productMetaInfo: {
      type: Object,
      default: () => ({}),
    },
    commentSummary: {
      type: Object,
      default: () => ({ starLevelAvg: 4.1 }),
    },
    loadingProductInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultImageUrl:
        'https://saas-fe-prod.pandova.com/static/pandova-mall/images/product/default-product.png',
      isShowAddCartSuccessTip: false, // 是否显示添加购物车成功提示
      currentSkuInfo: {}, // 当前sku商品信息
      materialId: -1,
      addCartLoading: false,
    }
  },
  computed: {
    isAlreadyLogin() {
      return this.$store.state.isAlreadyLogin
    },
    starLevel() {
      // return +this.commentSummary.starLevelAvg % 1 > 0
      //   ? +this.commentSummary.starLevelAvg.split('.')[0] + 0.5
      //   : +this.commentSummary.starLevelAvg
      return +this.productMetaInfo.starLevelAvg % 1 > 0
        ? +this.productMetaInfo.starLevelAvg.split('.')[0] + 0.5
        : +this.productMetaInfo.starLevelAvg
    },
    buttonDisabled() {
      return this.currentSkuInfo?.skuNum === 0
    },
    isHaveSpec() {
      return !!this.productMetaInfo?.specList?.length > 0
    },
    isShowSalePrice() {
      return !isEmptyValue(this.currentSkuInfo.salePrice)
    },
    isShowOriginPrice() {
      return isEmptyValue(this.currentSkuInfo.salePrice)
    },
    isThroughOriginPrice() {
      return (
        !isEmptyValue(this.currentSkuInfo.salePrice) &&
        this.currentSkuInfo.originalPrice > this.currentSkuInfo.salePrice
      )
    },
    isShowStock() {
      return (
        this.currentSkuInfo.focusStock === IS_TRACK_STOCK.TRACK_STOCK &&
        this.currentSkuInfo.skuStock <= 5
      )
    },
  },
  watch: {
    product: {
      handler: function () { },
      deep: true
    }
  },
  created() {
    this.init()
  },

  methods: {
    init() {
      this.currentSkuInfo = {
        originalPrice: this.productMetaInfo.originalPrice,
        salePrice: this.productMetaInfo.salePrice,
      }
      // 当商品没有规格时，需要初始化商品信息，默认sku的mapKey 为 '-'
      if (!this.isHaveSpec) {
        this.setSkuInfo('-')
      }
    },

    // 设置当前sku商品信息
    setSkuInfo(skuInfoMapKey) {
      const curSkuProductInfo = (this.productMetaInfo.specToSkuInfoMap || {})[skuInfoMapKey] || {}
      const materialList = this.productMetaInfo.materialList || []
      this.materialId = curSkuProductInfo.materialId
      const materialIndex = materialList.findIndex(item => this.materialId === item.id)
      const firstMaterialImgUrl = materialList[0]
        ? materialList[0].materialUrl
        : this.defaultImageUrl
      const imgUrl =
        materialIndex !== -1 ? materialList[materialIndex].materialUrl : firstMaterialImgUrl

      this.currentSkuInfo = {
        skuId: curSkuProductInfo.skuId,
        skuNum:
          curSkuProductInfo.focusStock === IS_TRACK_STOCK.UN_TRACK_STOCK ||
            curSkuProductInfo.stockCount > 0
            ? 1
            : 0,
        originalPrice: curSkuProductInfo.originalPrice,
        salePrice: curSkuProductInfo.salePrice,
        skuStock: curSkuProductInfo.stockCount || 0,
        costPrice: curSkuProductInfo.costPrice,
        focusStock: curSkuProductInfo.focusStock,
        imgUrl,
      }
    },

    // 构成下单或添加购物车商品信息，以sku为最小单位
    makeProductBySku() {
      const salePrice = isEmptyValue(this.currentSkuInfo.salePrice)
        ? this.currentSkuInfo.originalPrice
        : this.currentSkuInfo.salePrice
      const originalPrice = this.currentSkuInfo.originalPrice || 0
      const skuInfo = {
        ...this.currentSkuInfo,
        categoryId: this.productMetaInfo.categoryId,
        goodsId: this.productMetaInfo.id,
        goodsName: this.productMetaInfo.name,
        orderItemSpecsParamDTOList:
          this.$refs.specSelect && this.$refs.specSelect.getOrderItemSpecs(),
        salePrice,
        originalPrice,
        saleTotalPrice: salePrice * this.currentSkuInfo.skuNum,
      }
      return skuInfo
    },
    async addCart() {
      if (this.$refs.specSelect && this.$refs.specSelect.isNeedSelectSku()) {
        return
      }
      const params = this.makeProductBySku()
      MallAnalytics.mc('spmall_productdetail_addtocart_click', {
        spuId: params.goodsId,
        spuName: params.goodsName,
        skuId: params.skuId,
        ...sessionStorage.get('channelCode', {}),
      })
      this.isAlreadyLogin
        ? this.addOnlineShoppingCart(params)
        : this.addLocalShoppingCart({ ...params, valid: CART_PRODUCT_VALID.VALID })
    },
    // 添加购物车成功回调函数
    addCartSuccessCallBack() {
      this.currentSkuInfo = { ...this.currentSkuInfo, skuNum: 1 }
    },
    // 登陆状态时添加线上购物车
    async addOnlineShoppingCart(currentProduct) {
      try {
        this.addCartLoading = true
        await addShoppingCart([currentProduct])
        this.addCartSuccessCallBack()
        this.$store.dispatch('updateCartNumber')
        this.isShowAddCartSuccessTip = true
      } finally {
        this.addCartLoading = false
      }
    },
    // 校验添加购物车数量是否超过库存
    checkIsOverStock(currentProduct, cartProduct) {
      if (
        cartProduct &&
        currentProduct.focusStock === IS_TRACK_STOCK.TRACK_STOCK &&
        cartProduct.skuNum + currentProduct.skuNum > currentProduct.skuStock
      ) {
        return true
      }

      return false
    },
    // 为登陆状态购物车添加本地local storage存储
    addLocalShoppingCart(currentProduct) {
      const cartList = localStorage.get('shoppingCart', [])
      const index = cartList.findIndex(item => {
        return (
          item && item.goodsId === currentProduct.goodsId && item.skuId === currentProduct.skuId
        )
      })
      const cartProduct = cartList[index]

      if (this.checkIsOverStock(currentProduct, cartProduct)) {
        this.$message.error($t('shoppingCart.overStock'))
        return
      }

      if (index < 0) {
        cartList.push(currentProduct)
      } else {
        cartProduct.skuNum += currentProduct.skuNum
        cartProduct.saleTotalPrice += currentProduct.saleTotalPrice
      }

      localStorage.set('shoppingCart', cartList)
      this.addCartSuccessCallBack()
      this.$store.dispatch('updateCartNumber')
      this.isShowAddCartSuccessTip = true
    },
    // 选择商品去支付
    goPayment() {
      if (this.$refs.specSelect && this.$refs.specSelect.isNeedSelectSku()) {
        return
      }
      const params = this.makeProductBySku()
      MallAnalytics.mc('spmall_productdetail_buynow_click', {
        spuId: params.goodsId,
        spuName: params.goodsName,
        skuId: params.skuId,
        ...sessionStorage.get('channelCode', {}),
      })
      localStorage.set('accountProductList', [params])
      this.$router.push({
        path: '/checkout',
      })
    },
  },
}
</script>
<style lang="less" scoped>
.product-meta-info {
  position: relative;
  display: flex;
  width: 100%;

  &__detail {
    flex: 1;
    &__comments-summary {
      display: flex;
      align-items: center;

      &__number {
        padding-top: 4px;
        margin-left: 16px;
        // line-height: 24px;
        // color: var(--color-text);
        color: #2067dc;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Roboto';
        text-align: left;
        line-height: 20px;
      }
    }
    &__product-name {
      font-size: 26px;
      font-weight: 500;
      color: var(--color-text);
    }
    &__product-price {
      display: flex;
      font-size: 36px;
      font-weight: 500;
      line-height: 36px;
      &__sale {
        margin-right: 24px;
        color: var(--color-product-sale-price);
      }
      &__origin {
        font-size: 22px;
        color: var(--color-product-origin-price);
      }
    }
    &__product-quantity {
      &__title {
        margin-bottom: 12px;
      }
      &__body {
        display: flex;
        align-items: center;
        &__stock-tip {
          margin-left: 24px;
          color: #e34d59;
          &__title {
            margin-left: 4px;
          }
        }
      }
    }
    &__buy-operation {
      display: flex;
      justify-content: space-between;
      .op-button {
        width: 48%;
      }
    }
  }
}
@media (max-width: 768px) {
  .product-meta-info {
    display: block;
    width: 100%;

    &__detail {
      max-width: 880px;
      margin: 0 auto;
    }
  }
}
</style>
