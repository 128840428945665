<template>
  <div class="product-extra-info">
    <div class="product-extra-info__title">{{ $t('product.detail.additional') }}</div>
    <div class="product-extra-info__spe-list">
      <div
        v-for="item in extraInfo.specList || []"
        :key="item.id"
        class="product-extra-info__spe-list__item"
      >
        <div class="product-extra-info__spe-list__item__name">{{ item.name }}</div>
        <div
          v-for="sku in item.valueList"
          :key="sku.id"
          class="product-extra-info__spe-list__item__sku"
        >{{ sku.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    extraInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },

  computed: {},

  methods: {},
}
</script>
<style lang="less" scoped>
.product-extra-info {
  margin-top: 40px;
  &__title {
    margin-bottom: 12px;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    color: var(--color-title);
    text-align: left;
    letter-spacing: 0.2px;
  }
  &__spe-list {
    &__item {
      display: flex;
      align-items: center;
      height: 53px;
      padding-left: 16px;
      background: rgba(249, 250, 251, 1);
      opacity: 1;
      &__name {
        width: 100px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(149, 158, 173, 1);
      }
      &__sku {
        padding: 0 8px;
        font-size: 14px;
        color: rgba(22, 29, 37, 1);
      }
    }
  }
}
</style>
