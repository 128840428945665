<template>
  <div class="add-cart-success">
    <div class="add-cart-success__success-popup">
      <div class="add-cart-success__success-popup__header">
        <a-icon
          :style="{ color: '#8991A4', fontSize: '24px' }"
          type="close"
          @click="close"
        />
      </div>
      <div class="add-cart-success__success-popup__tip">
        <img
          v-compress="{
            src: 'https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/product/add-success.png',
          }"
          class="add-cart-success__success-popup__tip__image"
        />
        <div
          class="add-cart-success__success-popup__tip__text"
        >{{ $t('product.detail.addCartTip') }}</div>
      </div>
      <div class="add-cart-success__success-popup__btn-group">
        <op-button
          class="add-cart-success__success-popup__btn-group__btn"
          type="outline"
          @click="goCart"
        >{{ $t('product.detail.viewCart') }}</op-button>
        <op-button
          class="add-cart-success__success-popup__btn-group__btn"
          @click="continueShop"
        >{{ $t('product.detail.continueShopping') }}</op-button>
      </div>
    </div>
  </div>
</template>

<script>
import { localStorage, MallAnalytics } from '@/utils/index'
import { getOnlineCartList } from '@/api'
export default {
  components: {},
  props: {},
  data() {
    return {}
  },

  computed: {
    isAlreadyLogin() {
      return this.$store.state.isAlreadyLogin
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },
    goCart() {
      this.close()
      this.$router.push({
        path: '/cart',
      })
    },
    async goCheckout() {
      MallAnalytics.mc('spmall_productdetail_processcheckout_click')
      if (this.isAlreadyLogin) {
        const { data } = await getOnlineCartList()
        localStorage.set('accountProductList', data || [])
      } else {
        const cartList = localStorage.get('shoppingCart', [])
        localStorage.set('accountProductList', cartList)
      }
      this.close()
      this.$router.push({
        path: '/checkout',
      })
    },
    continueShop() {
      this.close()
    },
  },
}
</script>
<style lang="less" scoped>
.add-cart-success {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background: #181e2d33;
  &__success-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 574px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
    &__header {
      padding-top: 16px;
      padding-right: 16px;
      text-align: right;
    }
    &__tip {
      &__image {
        display: block;
        width: 64px;
        height: 64px;
        margin: 0 auto;
        object-fit: cover;
      }
      &__text {
        margin-top: 24px;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        color: #01040d;
        text-align: center;
      }
    }
    &__btn-group {
      display: flex;
      justify-content: space-between;
      padding: 48px 44px;
      &__btn {
        width: 45%;
      }
    }
  }
}
@media (max-width: 768px) {
  .add-cart-success {
    &__success-popup {
      width: 320px;

      &__btn-group {
        flex-wrap: wrap;
        justify-content: center;
        padding: 32px;
        &__btn {
          width: 80%;
          margin-bottom: 24px;
        }
      }
    }
  }
}
</style>
