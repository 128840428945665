var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-spec-select"},[_vm._l((_vm.specList),function(item){return _c('div',{key:item.id,staticClass:"product-spec-select__product-spec"},[_c('div',{staticClass:"product-spec-select__product-spec__name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"product-spec-select__product-spec__detail"},_vm._l((item.valueList),function(specValue){return _c('div',{key:specValue.id,staticClass:"product-spec-select__product-spec__detail__value",class:[
          _vm.selectedSpec[item.id] && _vm.selectedSpec[item.id].id === specValue.id
            ? 'product-spec-select__product-spec__detail__value--active'
            : '',
          _vm.unHasSkuList.includes(`${item.id}-${specValue.id}`)
            ? 'product-spec-select__product-spec__detail__value--disabled'
            : '',
        ],on:{"click":function($event){return _vm.specSelect(item, specValue)}}},[_vm._v(_vm._s(specValue.name))])}),0)])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowSelectTip),expression:"isShowSelectTip"}],staticClass:"select-sku-tip"},[_vm._v(_vm._s(_vm.$t('product.detail.skuSelectTip')))])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }